import React from 'react';
import badge from '../assets/badge.png';
import chariots from '../assets/chariots.png';
import archer from '../assets/archer.png';
import ares from '../assets/ares.png';
import $ from 'jquery';
let multiplier = 2620;
class Parallax extends React.Component{
    componentDidMount(){
        window.addEventListener('scroll', () => this.forceUpdate());
    }
    render(){
        if($(window).width() < 1000){
            multiplier = 1400;
        }
        return (
            <div style={this.props.isHome ? {transform: "translate3d(0px, " + ((-81.467 + $(window).scrollTop() / (20 * $(window).width() / multiplier))) + "%, 0px)"} : {transform: "translate3d(0px, " + ((-68.000 + $(window).scrollTop() / (20 * $(window).width() / multiplier))) + "%, 0px)"}} className='about-parallax'>
                <div style={{width: '100%', overflow: 'hidden'}}><img id='top-image' className='top-image' src={chariots} alt=''/></div>
                <img id='back-badge' style={window.innerWidth < 800 ? {width: '100%'} : {width: '500px'}} className='back-badge' src={badge} alt=''/>
                <img id='left-image' className='left-image' src={ares} alt=''/>
                <img className='right-image' id='right-image' src={archer} alt=''/>
                </div>
        )
    }
    

};


export default Parallax;
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { faPlay, faPause, faExpandArrowsAlt, faStepForward } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const properties = {
  autoplay: true,
  duration: 15000,
  transitionDuration: 4000,
  infinite: true,
  indicators: false,
  arrows: false,
}

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
class Slideshow extends Component {
  constructor(props){
    super(props);
    this.slideshow = React.createRef();
    this.state = {
      slideNames: [],
      loading: true,
      currIndex: 0,
      fading: false,
      playing: false,
      paused: false,
      //volume: localStorage.getItem("player-volume") ? localStorage.getItem("player-volume") : 50,
      timeout: undefined
    }
    //this.fadingFunc = this.fadingFunc.bind(this);
    this.OnClickPlay = this.OnClickPlay.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.startFading = this.startFading.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.TogglePaused = this.TogglePaused.bind(this);
    this.UpdateVolume = this.UpdateVolume.bind(this);
  }


  handleResize(){
    this.setState({windowWidth: window.innerWidth});
  }

  nextSlide(){
    if(this.state.currIndex === this.state.slideNames.length - 1){
      this.setState({currIndex: 0, fading: false});
    }else{
      this.setState({currIndex: this.state.currIndex + 1, fading: false});
    }
    this.setState({timeout: setTimeout(this.startFading, properties.duration)});
  }

  startFading(){
    this.setState({fading: true, timeout: setTimeout(this.nextSlide, properties.transitionDuration)});
  }

  componentDidMount(){
    //this.props.updateVolume(this.state.volume);
    window.addEventListener('resize', this.handleResize)
    this.setState({windowWidth: window.innerWidth});
    if(this.props.started && !this.props.paused){
      console.log(this.props.paused);
    this.setState({timeout: setTimeout(this.startFading, properties.duration)});
    }
  }

  componentWillUnmount(){
    if(this.state.timeout){
      clearTimeout(this.state.timeout);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.loading === true && nextProps.loading === false){
      //console.log(nextProps.config);
      //setTimeout(this.onTransition, 15000);
      const out = {}
      out.slideNames = shuffle(nextProps.config.slideshow);
      out.loading = false;
      return out;
    }
    return prevState;
  }

  OnClickPlay(){
    this.props.clickPlay();
    this.setState({timeout: setTimeout(this.startFading, properties.duration), playing: true});
  }

  TogglePaused(){
    if(this.state.paused){
      this.props.clickPlay();
      this.setState({timeout: setTimeout(this.startFading, properties.duration)});
    }else{
      this.props.clickPause();
      clearTimeout(this.state.timeout);
    }
    this.setState({paused: !this.state.paused});
  }

  UpdateVolume(vol){
    //his.setState({volume: vol.target.value});
    this.props.updateVolume(vol.target.value);
  }

  render(){
    let out = [];
    if(!this.props.loading){
      if(this.state.currIndex > this.state.slideNames.length - 5){
        const diff = this.state.slideNames.length - this.state.currIndex;
        out = this.state.slideNames.slice(this.state.currIndex, this.state.currIndex + diff).concat(this.state.slideNames.slice(0, 5 - diff));
      }else{
        out = this.state.slideNames.slice(this.state.currIndex, this.state.currIndex + 5);
      }
    }
    return (
      !this.props.loading ? <div className="slideshow" style={{width: (this.state.windowWidth * 5).toString() + "px"}} ref={this.slideshow}>
        {!this.props.started ? <div className="start-slides-background">
            <div className='start-outer-center' >
              <span className='start-border-extra' />
          <Button className='start-slides-button' onClick={this.OnClickPlay}>Start</Button>
          </div>
        </div> : <div className='start-slides-fadeaway'>{!this.props.isFullscreen && <div className='slides-player-parent'><div className='slides-player'>
            <Button className='slides-player-button' onClick={this.TogglePaused}><FontAwesomeIcon icon={this.props.paused ? faPlay : faPause} /></Button>
            <Button className='slides-fullscreen-button' onClick={this.props.GoFullscreen}><FontAwesomeIcon icon={faExpandArrowsAlt} /></Button>
            <Button className='slides-fullscreen-button' onClick={this.props.nextSong}><FontAwesomeIcon icon={faStepForward} /></Button>
            <input value={this.props.volume} onChange={this.UpdateVolume} min={0} max={100} type='range' className='slides-volume-control'></input>
          </div></div>}
          </div>}
        <div className={this.state.fading ? 'fadingOut each-fade' : 'each-fade'} style={{width: this.state.windowWidth + "px"}}>
              <div className='image-container'>
                <img src={"https://asha-logos-data.s3.amazonaws.com/public/slideshow/" + out[0]} alt=''/>
              </div>
            </div> 
        <div className={this.state.fading ? 'each-fade fadingIn' : 'each-fade'} style={this.state.fading ? {width: this.state.windowWidth + "px", left: -this.state.windowWidth + "px"} : {width: this.state.windowWidth + "px"}}>
              <div className='image-container'>
                <img src={"https://asha-logos-data.s3.amazonaws.com/public/slideshow/" + out[1]} alt=''/>
              </div>
            </div>
        {out.slice(2).map((fileName, index) => {
          return (
            <div key={fileName} className='each-fade' style={{width: this.state.windowWidth + "px"}}>
              <div className='image-container'>
                <img src={"https://asha-logos-data.s3.amazonaws.com/public/slideshow/" + fileName} alt=''/>
              </div>
            </div>
          );
        })}
      </div> : <div className='slideshow-loading'>LOADING</div>
    );
  }


}


export default Slideshow;

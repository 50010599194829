import React, { Component, Suspense } from 'react';
import Slideshow from './components/Slideshow.js';

import './App.css';
import $ from 'jquery';
import { Router, Route, Switch, Redirect, Link } from 'react-router-dom';

import {
  Navbar,
  NavItem,
  Nav,
  Container,
  Collapse
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';



import YouTube from 'react-youtube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import sword from './assets/sword.png';
import alexcoin from './assets/alex_coin.png';
import bowl from './assets/bowl.png';
import selecoin from './assets/sele_coin.png';
import Parallax from './components/Parallax';
import Fullscreen from "react-full-screen";

const Writings = React.lazy(() => import('./components/Writings.js'))
const Videos = React.lazy(() => import('./components/Videos.js'))
const Podcasts = React.lazy(() => import('./components/Podcasts.js'))
const Admin = React.lazy(() => import('./components/Admin.js'))
//import canAutoplay from 'can-autoplay';

let timer;


const youtubeOpts={
  height: '0',
  width: '0',
  playerVars: {
    autoplay: 0,
    loop: 1,
    listType: 'playlist',
    list: 'PLru9zi8j7G3PAKZy7cP-yXjxoGUkQUyws',
    isFullscreen: false
  }
};
class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      tab: 'Home',
      isOpen: true,
      blocked: false,
      started: false,
      paused: false,
      muted: false,
      volume: localStorage.getItem("player-volume") ? localStorage.getItem("player-volume") : 50,
      vidWidth: $(window).width,
      vidHeight: $(window).height
    }
    console.log(localStorage.getItem("player-volume"));
    this.reloadConfig = this.reloadConfig.bind(this);
    this.onYTReady = this.onYTReady.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.muteGlobal = this.muteGlobal.bind(this);
    this.clickPlay = this.clickPlay.bind(this);
    this.clickPause = this.clickPause.bind(this);
    this.updateVolume = this.updateVolume.bind(this);
  }

  reloadConfig(){
    this.setState({loading: true});
    fetch('https://asha-logos-data.s3-us-west-1.amazonaws.com/public/config.json')
      .then(response => response.json())
      .then(data => { this.setState({config: data, loading: false}); })
      .catch(error => console.log("Failed because: " + error));
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.hist.location.pathname === "/home"){
      const video = document.querySelector("video");
      if(video && this.state.started && !this.state.paused){
      video.play();
      }
    }
  }


  mutetoggle(e){
    if(e.keyCode === 77){
      if(this.player){
          this.setState({muted: !this.state.muted});
        if(this.player.isMuted()){
          localStorage.setItem('player-muted', false);
          this.player.unMute();
        }else{
          localStorage.setItem('player-muted', true);
          this.player.mute();
        }
      }
    }

  }

  muteGlobal(){
    //this.setState({muted: true});
    this.updateVolume(0);
    //if(this.player){
      //this.player.mute();
    //}
   // localStorage.setItem('player-muted', true);
  }

  updateDimensions(){
    if(!this.state.isFullscreen){
      this.setState({vidWidth: $(window).width(), vidHeight: $(window).height()});
    }
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount(){
    console.log(window.innerWidth);
    this.updateVolume(this.state.volume);
    window.addEventListener("resize", this.updateDimensions);
    this.reloadConfig();
    
    if(window.innerWidth > 800){
      var grid = $('#root');
    var circle= $('.fader');
      timer = setTimeout(function(){ circle.fadeOut('slow') }, 4000);
    grid.bind('mousemove touchmove tap swipeleft swipeup swipedown swiperight', function(e) {
      var circle= $('.fader');
      if (timer) clearTimeout(timer);
      if (!circle.is(":visible")) {
          circle.fadeIn('slow');
      }
      timer = setTimeout(function(){ circle.fadeOut('slow') }, 4000);
    });
    }
    


  }

  nextSong(){
    this.player.nextVideo();
  }

  onYTReady(e){
    this.player = e.target;
    
    
  }

  updateVolume(vol){
    this.setState({volume: vol});
    localStorage.setItem("player-volume", vol.toString());
    if(this.player){
      this.player.setVolume(vol);
    }
  }

  clickPlay(){
    this.setState({paused: false});
    if(!this.state.started){
      this.setState({started: true});
    }
    const video = document.querySelector("video");
    video.play();
    if(this.player){
      this.player.playVideo();
      const player = this.player;
      setTimeout( function() { 
        player.setShuffle(true); 
        player.setLoop(true);
      }, 2000);
    }

  }

  clickPause(){
    this.setState({paused: true});
    const video = document.querySelector("video");
    video.pause();
    if(this.player){
      this.player.pauseVideo();
    }

  }

  


  render(){
    if(this.state.loading){
      return (
        <div className='loadingbg'>
          <div>
          <FontAwesomeIcon icon={faSpinner} />
          </div>

        </div>
      )
    }
    const { hist } = this.props;
    
    return (
      <Router history={hist}>
          <div className='app'>
          <Navbar className={"App-header" + (window.location.pathname === '/home' ? " fader" : "")} style={{display: (window.location.pathname === '/admin' ? 'none' : 'block')}}>
            <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='m-auto'>
              <NavItem id='header-nav'><Link className='no-underline' to="/home"><div className={ 'nav-link home' + (window.location.pathname === '/home' ? " selected" : "")} onClick={() => { this.setState({tab: 'Home'}); window.scrollTo(0, 0);   if(timer && window.innerWidth > 800) clearTimeout(timer); var circle= $('.fader');
  timer = setTimeout(function(){ circle.fadeOut('slow') }, 4000);} }>Home</div></Link></NavItem>
              <NavItem id='header-nav'><Link className='no-underline' to="/videos"><div className={ 'nav-link link' + (window.location.pathname.includes('/videos') ? " selected" : "")} onClick={() => { this.setState({tab: 'Video'}); window.scrollTo(0, 0); }}>Video</div></Link></NavItem>
              <NavItem id='header-nav'><Link className='no-underline' to="/podcasts"><div className={ 'nav-link link' + (window.location.pathname.includes("/podcasts") ? " selected" : "")} onClick={() => { this.setState({tab: 'Podcast'}); }}>Podcast</div></Link></NavItem>
              <NavItem id='header-nav'><Link className='no-underline' to="/writings"><div className={ 'nav-link link' + (window.location.pathname.includes("/writings") ? " selected" : "")} onClick={() => { this.setState({tab: 'Writing'}); }}>Writing</div></Link></NavItem>
            </Nav>
            </Collapse>
          </Navbar>
          <Suspense fallback={<FontAwesomeIcon icon={faSpinner} />}>
        <Switch>
          <Route exact path="/admin">
            
              <Admin loading={this.state.loading} reloadConfig={this.reloadConfig} config={this.state.config} />
          </Route>
          <Route path="/home">
            <div className='smoke' style={{overflow: 'hidden'}} >
            <Fullscreen enabled={this.state.isFullscreen} onChange={isFull => this.setState({isFullscreen: isFull})}>
              <video style={this.state.isFullscreen ? {width: '100%'} : {height: '100%'}} muted ref={this.vidRef} className='stream' loop>
                <source src="https://asha-logos-data.s3-us-west-1.amazonaws.com/public/sun.mp4" type="video/mp4" />
              </video>
              <div className='slides' >
                <Slideshow volume={this.state.volume} paused={this.state.paused} nextSong={() => this.player.nextVideo()} started={this.state.started} isFullscreen={this.state.isFullscreen} GoFullscreen={() => this.setState({isFullscreen: true})} updateVolume={this.updateVolume} clickPause={this.clickPause} clickPlay={this.clickPlay} style={{height: ((window.innerWidth) * 0.625) + 'px', position: 'fixed'}} loading={this.state.loading} config={this.state.config}/>
              </div>
              </Fullscreen>
            </div>
          <div id='front-lower' className='lower'>
            <div className='about'>
                <div id='front-subtitle' className='sub-title'>
                    <img id='left-sword' className='left-sword' src={sword} alt=''/>
                  <h1>About</h1>
                    <img id='right-sword' className='right-sword' src={sword} alt=''/>

                </div> 
              <Parallax isHome={this.state.tab === 'Home'} />
              <Container>
                <div className='content' id="front-content">
    {!this.state.loading && ReactHtmlParser(this.state.config.about)}
                </div>
              </Container>
            </div>
            <div id='front-support' className='support'>
                <div tabIndex={0} onClick={() => { if(window.innerHeight > 800 && !this.state.supportOpen && window.scrollY !== $(document).height()) {$('html, body').animate({ scrollTop: $(document).height() }, "slow");} this.setState({supportOpen: !this.state.supportOpen}); }} >
                  <div className={'sub-title' + ((window.innerWidth < 800 || this.state.supportOpen) ? " open" : "")}>
                    <img id='left-sword' className='left-sword' src={sword} alt=''/>
                    <h1>Support</h1>
                    <img id='right-sword' className='right-sword' src={sword} alt=''/>
                  </div>
                </div>
                <Collapse isOpen={(window.innerWidth < 800 || this.state.supportOpen)}>
                  <div id='front-support-content' className='support-content'>
                    <img id="sele" className='selecoin' src={selecoin} alt=''/>
                    <img id="alex" className='alexcoin' src={alexcoin} alt=''/>
                    {!this.state.loading && ReactHtmlParser(this.state.config.support)}
                    
                    <img className='bowl' src={bowl} alt=''/>
                  </div>
                </Collapse>
            </div>
            </div>
          </Route>
          
          <Route path="/videos/:id" render={({match}) => {
            return(
              <Videos loading={this.state.loading} videos={this.state.config && this.state.config.videos} id={match.params.id}/>
            );
          }}>
          </Route>
          <Redirect from="/videos" to="/videos/newest"/>
          <Route path="/podcasts/:id" render={({match}) => {
            return(
              <Podcasts muteGlobal={this.muteGlobal} config={this.state.config} id={match.params.id}/>
            );
          }}>
          </Route>
          <Redirect from="/podcasts" to="/podcasts/newest"/>
          <Route path="/writings/:id" render={({match})=>{
            return (
              <Writings loading={this.state.loading} config={this.state.config} id={match.params.id} />
            );
          }}>
          </Route>
          <Route path="/writings" render={()=>{
            return (
              <Writings loading={this.state.loading} config={this.state.config} id={undefined} />
            );
          }}>
          </Route>
          <Redirect from="*" to="/home"/>
        </Switch>
        </Suspense>
          <YouTube containerClassName='yt-player' onReady={this.onYTReady} listId="PLhsj5QICk_kb1V6WUGpPRLyYC9C6hXTOP" opts={youtubeOpts} onStateChange={this.onYTStateChange}/>
        </div>
      </Router>
    );
  }
}

export default App;
/*<p>It's entirely due to the support of those on Patreon and donors that I'm able to devote myself to creative pursuits<br /> <i>Thank you</i></p>
                    <div className='cols'>
                      <div className='left'>
                        <div>Patreon</div>
                        <div>Alternative to Patreon</div>
                        <div>Paypal</div>
                      </div>
                      <div className='right'>
                        <div><a className='ashalink' href='https://www.patreon.com/AshaLogos' >https://www.patreon.com/AshaLogos</a></div>
                        <div><a className='ashalink' href='https://www.subscribestar.com/ashalogos' >https://www.subscribestar.com/ashalogos</a></div>
                        <div><a className='ashalink' href='mailto: ashalogos@gmail.com' >ashalogos@gmail.com</a></div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <p>Permanent Postal Address:<br />19240 Jensen Way NE<br />Unity #1014<br />Poulsbo WA 98370</p>*/
